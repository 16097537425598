import {
    DoubleEliminationBracket,
    Match,
    SVGViewer,
} from "@g-loot/react-tournament-brackets";

export function TournamentView() {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <h1>Tournaments</h1>
            Coming soon to a website near you!
        </div>
    );
}
