export const FACTIONS = {
    SELECT_FACTION: "Select Faction",
    SPACE_MARINES: "Space Marines",
    BLACK_TEMPLARS: "Black Templars",
    BLOOD_ANGELS: "Blood Angels",
    DARK_ANGELS: "Dark Angels",
    DEATHWATCH: "Deathwatch",
    GREY_KNIGHTS: "Grey Knights",
    SPACE_WOLVES: "Space Wolves",
    ULTRAMARINES: "Ultramarines",
    ADEPTA_SORORITAS: "Adepta Sororitas",
    ADEPTUS_CUSTODES: "Adeptus Custodes",
    ADEPTUS_MECHANICUS: "Adeptus Mechanicus",
    ASTRA_MILITARUM: "Astra Militarum",
    IMPERIAL_KNIGHTS: "Imperial Knights",
    CHAOS_DAEMONS: "Chaos Daemons",
    CHAOS_KNIGHTS: "Chaos Knights",
    CHAOS_SPACE_MARINES: "Chaos Space Marines",
    DEATH_GUARD: "Death Guard",
    THOUSAND_SONS: "Thousand Sons",
    WORLD_EATERS: "World Eaters",
    AELDARI: "Aeldari",
    DRUKHARI: "Drukhari",
    GENESTEALER_CULTS: "Genestealer Cults",
    LEAGUES_OF_VOTANN: "Leagues of Votann",
    NECRONS: "Necrons",
    ORKS: "Orks",
    TAU_EMPIRE: "Tau Empire",
    TYRANIDS: "Tyranids",
}

export const FACTIONS_ARRAY = [
    "Select Faction",
    "Space Marines",
    "Black Templars",
    "Blood Angels",
    "Dark Angels",
    "Deathwatch",
    "Grey Knights",
    "Space Wolves",
    "Ultramarines",
    "Adepta Sororitas",
    "Adeptus Custodes",
    "Adeptus Mechanicus",
    "Astra Militarum",
    "Imperial Knights",
    "Chaos Daemons",
    "Chaos Knights",
    "Chaos Space Marines",
    "Death Guard",
    "Thousand Sons",
    "World Eaters",
    "Aeldari",
    "Drukhari",
    "Genestealer Cults",
    "Leagues of Votann",
    "Necrons",
    "Orks",
    "Tau Empire",
    "Tyranids",
]